<template>
	<div>
		<div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
			<span>当前位置:</span>
			首页设置<span>&gt;</span>管理页脚<span>&gt;</span>
			新增页脚
			<button class="veadmin-iframe-button" onclick="window.location.href = '/pc/admin/footer/list';">
				<i class="el-icon-back"></i>
			</button>
			<button class="veadmin-iframe-button" onclick="location.reload();">
				<i class="el-icon-refresh-right"></i>
			</button>
		</div>
		<div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
			<el-form :model="footer" :rules="rules" ref="elForm" label-width="100px" class="footer-Form">
				<el-form-item label="名称" prop="label">
					<el-input v-model="footer.label"></el-input>
				</el-form-item>
				<el-form-item label="页脚类型" prop="type">
					<el-radio-group v-model="footer.type">
						<el-radio label="left">左侧</el-radio>
						<el-radio label="middle">中间</el-radio>
						<el-radio label="right">右侧</el-radio>
					</el-radio-group>
				</el-form-item>
				<!-- <el-form-item label="页脚类型" prop="type">
					<el-select v-model="footer.type" placeholder="请选择页脚类型">
						<el-option label="左侧" value="left"></el-option>
						<el-option label="中间" value="middle"></el-option>
						<el-option label="右侧" value="right"></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item label="跳转链接" prop="target_url">
					<el-input v-model="footer.target_url"></el-input>
				</el-form-item>
				<el-form-item label="新页面打开" prop="new_page">
					<el-switch v-model="footer.new_page"></el-switch>
				</el-form-item>
				<el-form-item label="权重" prop="weight">
					<el-input type="number" v-model.number="footer.weight" @mousewheel.native.prevent></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('elForm')">立即创建</el-button>
					<el-button @click="resetForm('elForm')">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import { apiAddFooter } from "@/api/admin/footer.js";
export default {
	data() {
		return {
			footer: {
				label: "",
				target_url: "",
				new_page: true,
				weight: 0,
				type: ""
			},
			rules: {
				label: [
					{ required: true, message: "请输入名称", trigger: "blur" },
					{ min: 1, max: 32, message: "长度在 1 到 32 个字符", trigger: "blur" }
				],
				type: [{ required: true, message: "请设置类型", trigger: "change" }],
				weight: [{ required: true, message: "请设置权重", trigger: "blur" }]
			}
		};
	},
	methods: {
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					//console.log(this.footer);
					let formData = new FormData();
					//formData.append("id", this.footer.id);
					formData.append("label", this.footer.label);
					formData.append("target_url", this.footer.target_url);
					formData.append("new_page", this.footer.new_page);
					formData.append("type", this.footer.type);
					formData.append("weight", this.footer.weight);
					/* for (let [a, b] of formData.entries()) {
						console.log(a + ":", b || '""');
					} */
					apiAddFooter(this.$store.state.token, formData).then((response) => {
						if (response.data.code == 200) {
							this.$message({ message: "新增成功", type: "success" });
							//执行成功1秒后跳转页面
							setTimeout(function() {
								window.location.href = "/pc/admin/footer/list";
							}, 1000);
						} else if (response.data.code == -100) {
							this.$confirm(response.data.message + "。将跳转至登录页面, 是否继续?", "提示", {
								confirmButtonText: "确定",
								cancelButtonText: "取消",
								type: "error"
							})
								.then(() => {
									//跳转到登录页面（路由跳转则滑动验证会失效）
									//this.$router.push({ path: "/login?target=" + window.location.href });
									window.location.href = "/login?target=" + window.location.href;
								})
								.catch(() => {
									this.$message({
										type: "warning",
										message: "已取消"
									});
								});
						} else {
							console.log("response>>", response);
							this.$message.error(response.data.message);
						}
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		}
	},
	mounted() {}
};
</script>
<style lang="scss">
/* element样式重置 start */
/* 去掉input尾部上下小箭头 start */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	-o-appearance: none !important;
	-ms-appearance: none !important;
	appearance: none !important;
	margin: 0;
}
input[type="number"] {
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	-o-appearance: textfield;
	-ms-appearance: textfield;
	appearance: textfield;
}
/* 去掉input尾部上下小箭头 end */
</style>
<style lang="scss" scoped>
.footer-Form {
	margin-top: 10px;
	margin-left: 6px;
	width: 400px;
}
</style>
